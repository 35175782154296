@tailwind base;
@tailwind components;
@tailwind utilities;

$primary: #1c274c;

@font-face {
  font-family: "medium";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_suit@1.0/SUIT-Medium.woff2")
    format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "semiBold";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_suit@1.0/SUIT-SemiBold.woff2")
    format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "bold";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_suit@1.0/SUIT-Bold.woff2")
    format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "extraBold";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_suit@1.0/SUIT-ExtraBold.woff2")
    format("woff2");
  font-weight: normal;
  font-style: normal;
}

* {
  font-family: "medium";
}
.semiBold {
  font-family: "semiBold";
}
.bold {
  font-family: "bold";
}
.extraBold {
  font-family: "extraBold";
}

.transition03s {
  transition: all 0.3s;
}

.p-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ell {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ells {
  display: -webkit-box;
  white-space: normal;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

// 스크롤바
.pretty-scrollbar {
  &::-webkit-scrollbar {
    width: 7px;
    background-color: transparent;

    &-thumb {
      background-color: rgb(143, 143, 143);
      height: 5px;
      border-radius: 50px;
    }
    &-track {
      background-color: rgba(0, 0, 0, 0);
    }
  }
}

// antd 스위치 비활성화 백그라운드
button.ant-switch {
  background-color: rgba(0, 0, 0, 0.25);
}
button.ant-btn-primary {
  background-color: #1677ff;
}
